import React from 'react'

const VideoTableRow = ({
    video,
    index,
    onClick
}) => {
    return (
        <tr className={(index % 2 ? 'bg-white' : 'bg-gray-50') + " video-row hover:bg-blue-200 cursor-pointer"} onClick={onClick}>
            <td className='video-row__date'>{ video.date }</td>
            <td className='video-row__title'>{ video.title }</td>
            <td className='video-row__description-wrapper'><span className='video-row__description'>{ video.description }</span></td>
        </tr>
    )
}

export default VideoTableRow