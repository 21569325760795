import React from 'react';

const QueryContext = React.createContext({
    search: '',
    categoryId: '',
    subCategoryId: '',
    page: 1,
    perPage: 10
});

export const QueryProvider = QueryContext.Provider;

export default QueryContext;