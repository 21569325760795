import {stringifyUrl, parseUrl} from 'query-string'

export default (newQueryParams) => {
  const {url, query} = parseUrl(window.location.href)

  const compactedParams = Object.fromEntries(
    Object.entries(newQueryParams).filter(([_, v]) => v)
  )

  const newUrl = stringifyUrl({url, query: compactedParams})

  window.history.pushState({}, '', newUrl)
}
