import React, { useContext, useEffect, useState } from 'react'
import { pluck, debounceTime, tap, map } from 'rxjs/operators'
import { useObservableState } from 'observable-hooks'
import QueryContext from '../utils/QueryContext'


const Search = ({ onSearchChange, updateQueryParams }) => {
    const queryParams = useContext(QueryContext);
    const [innerSearch, setInnerSearch] = useState(queryParams.search);
    const [_, onChange] = useObservableState(
        (event$) =>
            event$.pipe(
                // React synthetic event object will be reused.
                // Pluck the value out first.
                pluck('currentTarget', 'value'),
                tap(setInnerSearch),
                tap(onSearchChange),
                debounceTime(400),
                map((text) => updateSearch(text))
            ),
        false
    );

    useEffect(() => {
        setInnerSearch(queryParams.search);
    }, [queryParams.search])
    
    const updateSearch = (searchValue) => {
        updateQueryParams({ search: searchValue, page: 1 })
    };

    return (
        <div className="flex-grow">
        <input
            type="text"
            name="search"
            id="search"
            value={innerSearch}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-full"
            placeholder="Enter search..."
            onChange={onChange}
        />
        </div>
    )
}

export default Search
