import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import Search from './search'
import QueryContext from '../utils/QueryContext'

const perPageOptions = [
  {
    label: '10 Per Page',
    value: 10,
  },
  {
    label: '20 Per Page',
    value: 20,
  },
  {
    label: '30 Per Page',
    value: 30,
  },
  {
    label: '40 Per Page',
    value: 40,
  },
  {
    label: '50 Per Page',
    value: 50,
  },
]

const VideoFilter = ({
    onSearchChange,
    updateQueryParams,
}) => {
    const queryParams = useContext(QueryContext);
    const [categoryId, setCategoryId] = useState(queryParams.categoryId);
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])

    const loadCategories = (inputValue) => {
        return axios
        .get('/api/categories', { params: { q: inputValue } })
        .then(({ data }) => data.categories)
    }

    const loadSubCategories = (inputValue) => {
        return axios
        .get(`/api/categories/${queryParams.categoryId}/sub_categories`, {
            params: { q: inputValue },
        })
        .then(({ data }) => data.sub_categories)
    }

    useEffect(() => {
        if (!queryParams.categoryId) {
            setCategoryId('');
            loadCategories().then(setCategoryOptions);
        }
    }, [queryParams.categoryId])

    useEffect(() => {
        if (queryParams.categoryId) {
            setCategoryId(queryParams.categoryId);
            loadSubCategories().then(setSubCategoryOptions);
        }
    }, [queryParams.categoryId])

    const updateCategory = (option) => {
        updateQueryParams({
        categoryId: option?.value,
        subCategoryId: null,
        page: 1,
        })
    }

    const updateSubCategory = (option) => {
        updateQueryParams({ subCategoryId: option?.value, page: 1 })
    }

    const updatePerPage = (option) => {
        updateQueryParams({ perPage: option.value })
    }

    const resetFilters = () => {
        updateQueryParams({
            search: '',
            categoryId: null,
            subCategoryId: null,
            page: 1
        });
    }

    const currentCategory = categoryOptions.find(
        (option) => option.value == categoryId
    )

    const currentSubCategory = subCategoryOptions.find(
        (option) => option.value == queryParams.subCategoryId
    )

    const currentPerPage = perPageOptions.find(
        (option) => option.value == queryParams.perPage
    )

    return (
        <div className="flex flex-row flex-wrap flex-grow justify-center gap-x-3 px-3 sm:px-0">
            <div className="flex-grow w-full sm:w-1/2 mb-6">
                <label htmlFor="search" className="block text-sm font-medium text-gray-700 text-center">Search</label>
                <Search name="search" onChange={onSearchChange} updateQueryParams={updateQueryParams} />
            </div>        
            <div className="flex-grow mb-6">
                <label htmlFor="categories" className="block text-sm font-medium text-gray-700 text-center">Categories</label>
                <Select name="categories" isClearable options={categoryOptions} onChange={updateCategory} value={currentCategory || ''} />
            </div>
            {queryParams.categoryId && subCategoryOptions.length > 0 && (
                <div className="flex-grow mb-6">
                    <label htmlFor="sub-categories" className="block text-sm font-medium text-gray-700 text-center">Sub Categories</label>
                    <Select name="sub-categories" options={subCategoryOptions} isClearable value={currentSubCategory || ''} onChange={updateSubCategory} />
                </div>
            )}
            <div className="flex-grow mb-6">
                <label htmlFor="per-page" className="block text-sm font-medium text-gray-700 text-center">Per Page</label>
                <Select name="per-page" value={currentPerPage} options={perPageOptions} onChange={updatePerPage} />
            </div>
            <div className='flex-grow mb-6'>
                <button type="button"
                    className='inline-block mt-5 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer'
                    onClick={resetFilters}>Reset</button>
            </div>
        </div>
)}

export default VideoFilter
