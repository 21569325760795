// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import VideoFilter from '../components/video_filter'
import VideoTable from '../components/video_table'
import {QueryProvider} from '../utils/QueryContext'
import useQueryParams from '../utils/useQueryParams'

const Video = (props) => {
    const { queryParams, updateQueryParams, onSearchChange } = useQueryParams()

    return (
        <QueryProvider value={queryParams}>
            <header className='text-center my-6'>
                <h1 className='text-3xl mb-3'>Grand Rounds Plus</h1>
                <span role="doc-subtitle" className='font-bold'>Lecture Database</span>
            </header>
            <VideoFilter
                onSearchChange={onSearchChange}
                updateQueryParams={updateQueryParams}
            />
            <VideoTable
                queryParams={queryParams}
                updateQueryParams={updateQueryParams}
            />
        </QueryProvider>
    )
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Video />,
    document.getElementById('main').appendChild(document.createElement('div'))
  )
})
