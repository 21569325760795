import { useState, useEffect } from 'react'
import { parseUrl } from 'query-string'
import updateUrlQueryParams from './updateQueryParams'
import { pluck, debounceTime, tap, map } from 'rxjs/operators'
import { useObservableState } from 'observable-hooks'
import axios from 'axios'

const useQueryParams = () => {
    const [search, setSearch] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const onSearchChange = (searchValue) => {
        setSearch(searchValue);
    }

    const updateQueryParams = (newParams) => {
        if ('search' in newParams) {
            setSearch(newParams.search);
        }
        if ('categoryId' in newParams) {
            setCategoryId(newParams.categoryId);
        }
        if ('subCategoryId' in newParams) {
            setSubCategoryId(newParams.subCategoryId);
        }
        if('page' in newParams) {
            setPage(newParams.page);
        }
        if('perPage' in newParams) {
            setPerPage(newParams.perPage);
        }
    }

    useEffect(() => {
        const { url, query } = parseUrl(window.location.href);
        updateQueryParams(query);
    }, [])

    useEffect(() => {
        updateUrlQueryParams({search, categoryId, subCategoryId, page, perPage});
    }, [
        page,
        perPage,
        categoryId,
        subCategoryId,
        search,
    ])

    return {
        queryParams: {
            search,
            categoryId,
            subCategoryId,
            page,
            perPage
        },
        updateQueryParams,
        onSearchChange,
    }
}

export default useQueryParams
