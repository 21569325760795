import React, {useState, useEffect} from 'react'
import axios from 'axios'

import VideoTableRow from './video_table_row'
import Pagination from './pagination'
import VideoModal from './video_modal'

const VideoTable = ({
    queryParams: {categoryId, subCategoryId, search, page, perPage},
    updateQueryParams,
}) => {
    const [videos, setVideos] = useState([])
    const [selectedVideo, setSelectedVideo] = useState({})
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [paginationInfo, setPaginationInfo] = useState({})

    useEffect(() => {
        axios
        .get('/api/videos', {
            params: {
            category_id: categoryId,
            sub_category_id: subCategoryId,
            page,
            search,
            per_page: perPage,
            },
        })
        .then(({data}) => {
            setVideos(data.videos)
            setPaginationInfo(data.pagination_info)
        })
    }, [categoryId, subCategoryId, search, page, perPage])

    const onVideoClick = (video) => {
        setSelectedVideo(video);
        setShowVideoModal(true);
    }

    const onVideoClose = () => {
        setShowVideoModal(false);
    }

    const handlePreviousPage = () => {
        updateQueryParams({page: paginationInfo.previousPage})
    }

    const handleNextPage = () => {
        updateQueryParams({page: paginationInfo.nextPage})
    }

    if (videos.length == 0) {
        return (
        <div className="m-3 text-center text-lg">
            <h2> There are no results. </h2>
        </div>
        )
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="videos min-w-full divide-y divide-gray-200 -mb-0">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    Date
                                    </th>
                                    <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    Title
                                    </th>
                                    <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    Description
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {videos.map((video, idx) => (
                                    <VideoTableRow key={video.id} video={video} index={idx} onClick={() => { onVideoClick(video); }} />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination
                {...paginationInfo}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
            />
            <VideoModal show={showVideoModal} video={selectedVideo} onClose={onVideoClose} />
        </>
    )
}

export default VideoTable
