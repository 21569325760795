import React from 'react'

const VideoModal = ({show, onClose, video}) => {
    if (!show) {
        return null;
    }
    return (
        <div className='fixed inset-0 p-4 flex flex-row bg-black bg-opacity-50 items-center justify-center'>
            <div className='bg-white p-4 flex flex-col rounded w-full overflow-y-auto h-full sm:h-auto' style={{maxWidth: "768px"}}>
                <header className='flex flex-row content-center w-full'>
                    <h1 className='text-3xl'>{video.title}</h1>
                    <button className='ml-auto pl-4 text-3xl border-0 opacity-50 hover:opacity-100 self-start' type="button" onClick={onClose} title="Close">
                        <svg aria-hidden='true' focusable='false' role='presentation' width='1em' height='1em' viewBox='0 0 24 24'>
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </header>
                    <time className='block mt-4 font-bold' dateTime={video.date}>{new Date(video.date).toLocaleDateString(undefined, {year: "numeric", month: "long", day: "numeric"})}</time>
                    <div className='my-4'>
                        {video.description}
                    </div>
                    <span className='block mb-4'>Source: <em><a href={video.url} target='_blank' rel='noopener noreferrer' className='break-words text-blue-700 underline hover:text-blue-900 hover:no-underline focus:text-blue-900 focus:no-underline'>{video.url ? video.url : 'No source avaialble'}</a></em></span>
                <footer className='mt-auto sm:mt-4 text-right'>
                    {video.url &&
                        <a href={video.url} target='_blank' rel='noopener noreferrer' className='inline-block px-4 py-2 mx-1 rounded bg-blue-200 hover:bg-blue-400 focus:bg-blue-400'>View Lecture<span className='sr-only'>: {video.title}</span></a>
                    }
                    <button className='px-4 py-2 mx-1 bg-gray-200 hover:bg-gray-400 focus:bg-gray-400 rounded' type="button" onClick={onClose}>Close</button>
                </footer>
            </div>
        </div>
    )
}

export default VideoModal